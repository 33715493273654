import React, { useState } from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { useTheme } from 'next-themes';
import chatIcon from '../Assets/icons/repat gpt-01.png';
import chatIconHover from '../Assets/icons/repat gpt state-01.png';

const Layout = ({
  children,
  setShowFloatingWindow,
  setShowMainScreen,
  sendMessage,
  setInput,
  input,
  sendMessageLoading
}) => {
  const { theme } = useTheme();

  const [showChat, setShowChat] = useState(false);

const toggleChat = () => {
    setShowChat(prevShowChat => !prevShowChat);
  };

const [visible, setVisible] = useState(true);

  return (
    <>
      
        <div
          className={`build-up-layout-container`} //${showFloatingWindow ? 'show' : ''}
          style={{ height: "200px", display: showChat ? "flex" : "none" }}
        >
          <Header setShowFloatingWindow={setShowFloatingWindow} setShowMainScreen={setShowMainScreen} />
          <main className="grow flex flex-col w-full">
            {children}
          </main>
          <Footer
            input={input}
            sendMessageLoading={sendMessageLoading}
            sendMessage={sendMessage}
            setInput={setInput}
          />
        </div>
        { visible ?
       ( <div className="chat-button" onClick={toggleChat} onMouseEnter={() => setVisible(!visible)} >
          <img src={chatIcon} alt="chat" />
        </div>) :
        (
        <div className="chat-button-hover" onClick={toggleChat} onMouseLeave={() => setVisible(!visible)}>
          <img src={chatIconHover} alt="chat" />
        </div>)
}
      
    </>
  );
};

export default Layout;
